import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';

import { debounce } from 'libs/lodashAlt';

import RoomsService from 'shared/rooms-vcp-service';

const MINIMUM_LENGTH = 3;

const SelectModel = ({ value, setValue }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const debouncedPromiseOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      let results = [];
      if (inputValue.length < MINIMUM_LENGTH) resolve([]);
      else if (!isNaN(inputValue)) {
        // es número
        setIsLoading(false);
        setValue(inputValue);
        results.push({
          id: inputValue,
          label: inputValue,
          value: inputValue,
        });
        resolve(results);
      } else {
        setIsLoading(true);

        RoomsService.list({
          limit: 10,
          query: inputValue,
          //sitemode: 'REGISTERED',
        })
          .then(({ message, code }) => {
            if (code !== 200) resolve([]);
            else {
              message.results.map((item, index) =>
                results.push({
                  id: item.id,
                  label: (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      <div style={{ position: 'relative' }}>
                        {item.online === 1 && (
                          <div
                            style={{
                              backgroundColor: '#22C55E',
                              borderRadius: '9999px',
                              width: '.5rem',
                              height: '.5rem',
                              top: 0,
                              right: '-.25rem',
                              position: 'absolute',
                            }}
                          />
                        )}
                        <img src={item.thumb} alt={item.nick} width={24} height={24} style={{ borderRadius: '9999px' }} />
                      </div>
                      <span>{item.nick + ' (' + item.id + ')'}</span>
                    </div>
                  ),
                  value: item.id,
                })
              );
              resolve(results);
            }
          })
          .catch((error) => {
            resolve([]);
          })
          .finally(() => setIsLoading(false));
      }
    });
  };

  const onChange = (option) => {
    setSelectedOption(option);
    setValue(option?.value || '');
  };

  const LoadingMessage = (props) => {
    return (
      <div>
        <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
          <FormattedMessage id="global-loading" />
        </div>
      </div>
    );
  };

  return (
    <AsyncSelect
      styles={{
        control: (styles) => ({
          ...styles,
          borderColor: '#ced4da',
          color: '#4b5056',
          fontWeight: '400',
          fontSize: '1rem',
        }),
      }}
      value={selectedOption}
      defaultValue={selectedOption}
      placeholder={formatMessage({ id: 'global-search-model' })}
      loadOptions={debouncedPromiseOptions}
      onChange={onChange}
      isClearable
      noOptionsMessage={() => {
        if (value.length < MINIMUM_LENGTH) return '';
        else return formatMessage({ id: 'global-no-results' });
      }}
      isLoading={isLoading}
      components={{ LoadingMessage }}
    />
  );
};

export default SelectModel;
