import request from './api-oc-v2-service';

// There are more filters available in the API:
// if something else is needed, check Swagger.

export const list = ({
  limit = 24,
  offset = 0,
  segment,
  mode,
  category,
  lang,
  minage,
  maxage,
  country,
  online,
  excludeid,
  excludesegment,
  query,
  mobile,
  toy,
  minprivateprice,
  maxprivateprice,
  sitemode,
} = {}) => {
  const params = {
    limit,
    offset,
    segment,
    mode,
    category,
    lang,
    minage,
    maxage,
    country,
    online,
    excludeid,
    excludesegment,
    q: query,
    mobile,
    toy,
    minprivateprice,
    maxprivateprice,
    sitemode,
  };

  return request({
    method: 'GET',
    url: `/rooms/oc`,
    params: params,
  });
};

const RoomsService = {
  list,
};

export default RoomsService;
