import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Encabezado de pestañas */}
      <div className="tabs-header" style={styles.header}>
        {React.Children.map(children, (child, index) => (
          <button
            style={{
              ...styles.tabButton,
              ...(activeTab === index ? styles.activeTabButton : {}),
            }}
            onClick={() => setActiveTab(index)}>
            {child.props.title}
          </button>
        ))}
      </div>

      {/* Contenido de la pestaña activa */}
      <div style={styles.tabContent}>{React.Children.toArray(children)[activeTab]}</div>
    </div>
  );
};

const Tab = ({ children }) => {
  return <div>{children}</div>;
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = {
  header: {
    display: 'flex',
    overflowX: 'auto', // Habilitar desplazamiento horizontal
    whiteSpace: 'nowrap', // Evitar que las pestañas se envuelvan
    scrollbarWidth: 'thin',
  },
  tabButton: {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: '1px solid #c8d1db',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    color: '#495057',
    flex: '0 0 auto', // Evitar que los botones se expandan
    borderBottom: '0px',
  },
  activeTabButton: {
    border: '1px solid #c8d1db',
    backgroundColor: '#e5e7eb',
    borderBottom: 'none',
    fontWeight: 'bold',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
  },
  tabContent: {
    padding: '20px',
    border: '1px solid #c8d1db',
    borderRadius: '0.25rem',
  },
};

export { Tabs, Tab };
