import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

import WhiteLabelsService from 'shared/white-labels-service';

import Card from 'components/card';
import Button from 'components/button';
import { Tabs, Tab } from 'components/Tabs';
import HlsDocs from 'templates/promocion/LiveRoom/HlsDocs';
import PlayerDocs from 'templates/promocion/LiveRoom/PlayerDocs';
import SelectModel from 'templates/promocion/LiveRoom/SelectModel';

import './index.scss';

const LiveRoom = () => {
  const { formatMessage } = useIntl();
  const { id_affiliate } = useSelector((state) => state.auth.auth);

  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const iframeBaseUrl = 'https://recursos.promocionesweb.com/oletrafficfreeplayer';
  const [ttl, setTtl] = useState(5);
  const [modelId, setModelId] = useState('');
  const [afno, setAfno] = useState('');
  const [domain, setDomain] = useState('');
  const [path, setPath] = useState('');
  const prefixAfno = `1-${id_affiliate || ''}-`;
  const [iterate, setIterate] = useState(true);
  const [nick, setNick] = useState(true);
  const [audio, setAudio] = useState(false);
  const [whitelabels, setWhitelabels] = useState([]);
  const [randomModel, setRandomModel] = useState(true);

  const [queryString, setQueryString] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'SET_LOADING', loading: true });

      const { message, status } = await WhiteLabelsService.list({
        page: 1,
        perPage: 99,
      });
      if (status === 'Accepted') setWhitelabels(message.data);

      dispatch({ type: 'SET_LOADING', loading: false });
    })();
  }, []);

  useEffect(() => {
    const queryString = new URLSearchParams();
    if (ttl) queryString.append('ttl', ttl);
    if (modelId) queryString.append('modelId', modelId);
    if (afno) queryString.append('afno', prefixAfno + afno);
    if (domain) queryString.append('domain', domain);
    if (path) queryString.append('path', path);

    if (nick) queryString.append('nick', nick);
    if (iterate) queryString.append('iterate', iterate);
    if (audio) queryString.append('audio', audio);

    setQueryString(queryString.toString());
  }, [ttl, modelId, afno, audio, domain, iterate, nick, path]);

  useEffect(() => {
    if (modelId) setIterate(false);
  }, [modelId]);

  const copyToClipboard = (e) => {
    e.preventDefault();
    setCopied(true);
    navigator.clipboard.writeText(`<iframe src='${iframeBaseUrl}?${queryString}' />`);
    toast.success(<FormattedMessage id="global-success-operation" />);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <Card title={<FormattedMessage id="sidebar-promo-live-room" className="liveroom" />}>
      <Tabs>
        <Tab title={<FormattedMessage id="global-player" />}>
          <div className="liveroom">
            <div className="grid-cols grid-cols-small">
              <div className="text-left">
                <form onSubmit={() => {}}>
                  <label className="grid-cols">
                    <FormattedMessage id="global-live-room-max-play-time" />
                    <Form.Control type="number" name="ttl" value={ttl} onChange={(e) => setTtl(e.target.value)} min="3" max="3600" />
                  </label>
                  <label className="grid-cols">
                    Afno
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                      }}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0.375rem 0.75rem',
                          marginBottom: 0,
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: 1.5,
                          color: '#495057',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          backgroundColor: '#e9ecef',
                          border: '1px solid #ced4da',
                          borderRadius: '0.25rem 0 0 0.25rem',
                        }}>
                        {prefixAfno}
                      </span>
                      <Form.Control
                        className=""
                        value={afno}
                        placeholder=""
                        name="afno"
                        onChange={(e) => setAfno(e.target.value)}
                        style={{
                          borderRadius: '0 0.25rem 0.25rem 0',
                        }}
                      />
                    </div>
                  </label>
                  {whitelabels.length > 0 && (
                    <label className="grid-cols">
                      <FormattedMessage id="global-domain" />
                      <select
                        className="form-control"
                        name="domain"
                        defaultValue={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        disabled={loading}>
                        <option value="">{formatMessage({ id: 'global-select-an-option' })}</option>
                        {whitelabels.map((whitelabel, index) => (
                          <option key={index} value={whitelabel.DOMAINS[0].URL}>
                            {whitelabel.DOMAINS[0].URL}
                          </option>
                        ))}
                      </select>
                    </label>
                  )}
                  <label className="grid-cols">
                    <FormattedMessage id="global-live-room-link" />
                    <select className="form-control" name="path" value={path} onChange={(e) => setPath(e.target.value)}>
                      <option value="">{formatMessage({ id: 'global-live-room-model-page' })}</option>
                      <option value="register">{formatMessage({ id: 'global-live-room-register-page' })}</option>
                      <option value="login">{formatMessage({ id: 'global-live-room-login-page' })}</option>
                    </select>
                  </label>
                  <label className="grid-cols">
                    <FormattedMessage id="global-live-room-random-model" />
                    <input
                      style={{ width: '1rem', height: '1rem' }}
                      className="form-control"
                      type="checkbox"
                      name="randomModel"
                      checked={randomModel}
                      onChange={(e) => {
                        setRandomModel(e.target.checked);
                        setModelId('');
                      }}
                    />
                  </label>
                  {!randomModel && (
                    <label className="grid-cols">
                      <FormattedMessage id="global-model" />
                      <SelectModel value={modelId} setValue={setModelId} />
                    </label>
                  )}
                  <label className="grid-cols">
                    <FormattedMessage id="global-live-room-load-next-room" />
                    <input
                      style={{ width: '1rem', height: '1rem' }}
                      className="form-control"
                      type="checkbox"
                      name="iterate"
                      checked={iterate}
                      onChange={(e) => setIterate(e.target.checked)}
                      disabled={modelId}
                    />
                  </label>
                  <label className="grid-cols">
                    <FormattedMessage id="global-live-room-show-nick" />
                    <input
                      style={{ width: '1rem', height: '1rem' }}
                      className="form-control"
                      type="checkbox"
                      name="nick"
                      checked={nick}
                      onChange={(e) => setNick(e.target.checked)}
                    />
                  </label>
                  <label className="grid-cols">
                    Audio
                    <input
                      style={{ width: '1rem', height: '1rem' }}
                      className="form-control"
                      type="checkbox"
                      name="audio"
                      checked={audio}
                      onChange={(e) => setAudio(e.target.checked)}
                    />
                  </label>

                  <hr />
                  <textarea className="form-control" value={`<iframe src='${iframeBaseUrl}?${queryString}' />`} rows="4" readOnly />
                  <div
                    style={{
                      height: '2rem',
                    }}
                  />
                  <div className="text-center">
                    <Button onClick={copyToClipboard}>
                      {copied ? (
                        <>
                          <FormattedMessage id="global-copied" />
                        </>
                      ) : (
                        <>
                          <FormattedMessage id="promo-copy-code-clipboard" />
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              <iframe
                src={`${iframeBaseUrl}?${queryString}`}
                width="100%"
                height="100%"
                title="Live Room"
                style={{
                  border: 'none',
                }}
                allowFullScreen
              />
            </div>
          </div>
          <hr />
          <PlayerDocs />
        </Tab>
        <Tab title="Live HLS Stream">
          <HlsDocs />
        </Tab>
      </Tabs>
    </Card>
  );
};

export default LiveRoom;
