const HlsDocs = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>HLS Free Streaming</h1>

      <h2>Introduction</h2>
      <p>
        Free streaming in HLS is available <strong>ONLY</strong> for rooms in <strong>free</strong> mode.
      </p>
      <p>
        When a room changes to <code>private</code> or <code>vip</code> mode, free streams are destroyed.
      </p>
      <p>Play time can be customized using the TTL parameter, from 3 seconds to 3,600 seconds.</p>

      <h2>Flow</h2>
      <ol>
        <li>Get partner token.</li>
        <li>
          Request video list. We recommend requesting with the <code>mode</code> filter: <code>/rooms/oc?mode=free</code>
        </li>
        <li>Request HLS URL:</li>
      </ol>
      <pre style={{ backgroundColor: '#cccccc', padding: '10px' }}>
        <code>
          curl --location 'https://apiv2.videochatprovider.com/performer/12345/live/hls?ttl=60' \ --header 'partner-token: xxxxxx'
        </code>
      </pre>
      <p>4. Use returned URL in your player.</p>
      <pre style={{ backgroundColor: '#cccccc', padding: '10px' }}>
        <code>
          {'{'}
          "message":"https://xxxx-prod-euw1b-xxx.xxxxx/live/xxxxx.m3u8?accessToken=xxxx....."
          {'}'}
        </code>
      </pre>

      <h2>Examples</h2>

      <h3>VLC Player</h3>
      <p>
        Download VLC Player: <a href="https://www.videolan.org/vlc/">https://www.videolan.org/vlc/</a>
      </p>
      <ol>
        <li>
          File -&gt; Open network...
          <br />
          <img src="/images/liveroom/vlc1.png" alt="VLC Step 1" style={{ width: '100%', maxWidth: '500px' }} />
        </li>
        <li>
          Paste URL from request <code>performer/{`{performer_id/live/hls?ttl=60`}</code>
          <br />
          <img src="/images/liveroom/vlc2.png" alt="VLC Step 2" style={{ width: '100%', maxWidth: '500px' }} />
        </li>
      </ol>

      <h3>React</h3>
      <p>Install a library for HLS streams:</p>
      <pre style={{ backgroundColor: '#cccccc', padding: '10px' }}>
        <code>npm install hls.js</code>
      </pre>
      <p>Sample component:</p>
      <pre style={{ backgroundColor: '#cccccc', padding: '10px' }}>
        <code>
          {`import { useEffect, useRef } from "react";
import Hls from "hls.js";

export default function VideoPlayer() {
  const videoRef = useRef(null);
  const hlsSrc = "video url";

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(hlsSrc);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        console.log("HLS video is attached and ready to play.");
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS error:", data);
      });

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      // Fallback for Safari
      videoRef.current.src = hlsSrc;
    } else {
      console.error("HLS is not supported in this browser.");
    }
  }, [hlsSrc]);

  return (
    <div>
      <video
        ref={videoRef}
        controls
        autoPlay
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
}`}
        </code>
      </pre>

      <h3>Vanilla JS</h3>
      <pre style={{ backgroundColor: '#cccccc', padding: '10px' }}>
        <code>
          {`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>HLS Video Player</title>
  <style>
    video {
      width: 100%;
      height: auto;
    }
  </style>
  <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
</head>
<body>
  <div>
    <video id="video-player" controls autoplay></video>
  </div>
  <script>
    document.addEventListener("DOMContentLoaded", () => {
      const video = document.getElementById("video-player");
      const hlsSrc = "https://your-hls-video-url.m3u8";

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(hlsSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log("HLS video is attached and ready to play.");
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS error:", data);
        });

        // Clean up Hls.js instance when needed (e.g., on page unload)
        window.addEventListener("beforeunload", () => hls.destroy());
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Fallback for Safari
        video.src = hlsSrc;
      } else {
        console.error("HLS is not supported in this browser.");
      }
    });
  </script>
</body>
</html>`}
        </code>
      </pre>
    </div>
  );
};

export default HlsDocs;
