export default function PlayerDocs() {
  return (
    <div>
      <h2>Free Streaming Player</h2>
      <section>
        <h3>Introduction</h3>
        <p>
          The free streaming player is a standalone player designed for online rooms in free mode. If a room is offline or in a paying
          session, a video from the performer will be played instead.
          <p>If no modelId is provided, a random online room will be played.</p>
        </p>
        <p>The player is provided as an iframe:</p>
        <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/</code>
        <p>
          It can be customized by sending parameters. If no parameters are provided, the player will load the first online free room and
          will link to:
        </p>
        <code>https://www.olecams.com/es/webcam/&#123;performerNick&#125;</code>
      </section>

      <details>
        <summary>Flow</summary>
        <section>
          <ol>
            <li>Get partner token.</li>
            <li>
              Request the room list using the <code>mode=free</code> filter:
              <code>/rooms/oc?mode=free</code>
              Extract the performer ID from the list.
            </li>
            <li>
              Load the iframe:
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;</code>
            </li>
          </ol>
        </section>
      </details>

      <details>
        <summary>Customization</summary>
        <section>
          <ul>
            <li>
              <strong>modelId:</strong> This property specifies the performer's ID for the video feed. If the performer is offline, it will
              play the performer's presentation video instead. Incompatibility with iterate: If you set a modelId, the iteration feature
              (which automatically loads the next room when the current stream ends) will be disabled.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;</code>
            </li>
            <li>
              <strong>nick:</strong> Displays the performer’s nickname.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&nick</code>
            </li>
            <li>
              <strong>ttl:</strong> Sets the playing time (time to live). Default is 5 seconds.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&ttl=30</code>
            </li>
            <li>
              <strong>iterate:</strong> Automatically loads the next available room when the stream stops. If a modelId is set, this feature
              will be turned off, as it is not compatible with manually controlling which model (or performer's video) is being played.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&iterate</code>
            </li>
            <li>
              <strong>afno:</strong> Specifies affiliate information.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&afno=1-1-test</code>
            </li>
            <li>
              <strong>domain:</strong> Specifies the affiliate's white-label domain.
              <br />
              <code>
                https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&domain=www.mywhitelabel.com
              </code>
            </li>
            <li>
              <strong>path:</strong>{' '}
              <span>The path of the player's link. If not provided, the user will be redirected to the performer's page.</span>
              <br />
              Allowed values:
              <ul>
                <li>register: Redirects the user to the sign-up page.</li>
                <li>login: Redirects the user to the sign-in page.</li>
                <li>performer: Redirects the user to the performer's page.</li>
              </ul>
              This specifies the path of the click when the player is clicked.
            </li>
            <li>
              <strong>audio:</strong> Unmutes the player.
              <br />
              <code>https://recursos.promocionesweb.com/oletrafficfreeplayer/?modelId=&#123;performerID&#125;&audio</code>
            </li>
          </ul>
        </section>
      </details>
    </div>
  );
}
