import { useParams } from 'react-router';

import Layout from 'components/layout';
import Landing from './landing';
import Banners from './banners';
import WhiteBrand from './whitebrand';
import WhiteBrandForm from './whitebrand/form';
import WhiteBrandEdit from './whitebrand/editForm';
import RoomLists from './roomLists';
import RoomListsForm from './roomLists/mainForm';

import LiveRoom from './LiveRoom';

const Promocion = () => {
  const { section, part } = useParams();

  return (
    <Layout>
      {section === 'landing' && <Landing />}
      {section === 'banners' && <Banners />}
      {section === 'marca-blanca' && !part && <WhiteBrand />}
      {section === 'marca-blanca' && part === 'form' && <WhiteBrandForm />}
      {section === 'marca-blanca' && part === 'edit' && <WhiteBrandEdit />}
      {section === 'banners-dinamicos' && !part && <RoomLists />}
      {section === 'banners-dinamicos' && part === 'form' && <RoomListsForm />}
      {section === 'live-room' && <LiveRoom />}
    </Layout>
  );
};

export default Promocion;
